import * as React from "react";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";
import "../i18n";

const Pagination = ({ pageContext }) => {
    const { t } = useTranslation();
    const { previousPagePath, nextPagePath } = pageContext;

    return (
        <nav>
            <ul className="pagination justify-content-center">
                <li className="page-item">
                    {previousPagePath && (
                        <Link className="page-link" to={previousPagePath}>← {t("newerPosts")}</Link>
                    )}
                </li>
                <li className="page-item">
                    {nextPagePath && (
                        <Link className="page-link" to={nextPagePath}>{t("olderPosts")} →</Link>
                    )}
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
